import React from 'react';
import Layout from '../components/layout';
// Gatsby supports TypeScript natively!
import { PageProps, Link, graphql } from "gatsby"
import './style.css'
type Data = {
    site: {
        siteMetadata: {
            title: string
        }
    }
}

const About = ({ data }: PageProps<Data>) => {
    const siteTitle = data.site.siteMetadata.title

    return (
        <Layout title={siteTitle}>
            <h2>What does Anny do?</h2>
            <p>Anny is a writer, artist, and software engineer. If there's time leftover she'll draw a comic or two.</p>
            <p>She was trilingual at age of twelve, having lived on three continents (Asia, Europe, and Canada). She's a two-time immigrant and lives in the Bay Area.</p>
            <p>She played the bagpipes in her teens and traveled to Scotland to compete in 2008.</p>
            <p>She has an insatiable appetite for personal finance, comics, and clear writing.</p>

            <p>Anny holds a degree in Computing Science and Business Administration from Simon Fraser University.</p>

            <h2 id="did-you-play-the-bagpipes">You played the bagpipes?!</h2>

            <p>Yes I did for 7 years. This is a video me playing the Canadian anthem.</p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/Fjt2zt7SoqU?rel=0" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen=""></iframe>

            <p>Here is another video of me playing Amazing Grace with the wonderful Simon Fraser University Pipe Band.</p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/s7pUbkJcBRU?rel=0" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen=""></iframe>

            <div className="entry">
                <h4>Publications</h4>

                <p><a href='https://dev.to/heroku/javascript-quirks-numbers-edition-3ppa'>JavaScript Quirks on Dev.io</a> <span>February 2020</span></p>
                <p><a href='https://developer.salesforce.com/blogs/author/anny-he'>Salesforce developer blog</a> <span>July 2019 to February 2020</span></p>
                <p><a href="https://engineering.salesforce.com/https-medium-com-annyhe-nerdy-new-years-resolution-share-your-technical-project-at-a-conference-5c2719d3684c">Present your technical project at a conference, on Salesforce Engineering Blog</a> <span>Jan 2018</span></p>

                <h4>Speaking Engagements</h4>
                <p><a href="/SFU1633C.pdf">How we built, launched, and scaled Womxn in Engineering at Splunk</a> <span>October 2020</span></p>
                <p><a href='https://trailhead.salesforce.com/live/videos/a2r3k000001WFUB/javascript-quirks-numbers/?type=all'>JavaScript Quirks: numbers edition</a> <span>February 2020</span></p>
                <p><a href='https://www.youtube.com/watch?v=cPx3yJBaGx0'>Salesforce Developer Preview – Release Readiness LIVE, Spring '20</a> <span>February 2020</span></p>

                <p><a href='https://www.youtube.com/watch?v=cGOnLu8SnXM'>Anny He (Developer Evangelist Salesforce) shows how to build Quip Live Apps with React</a> <span>December 2018</span></p>

                <p><a href="https://www.youtube.com/watch?v=hPBK-qaGFKI">Visualizing Infrastructure Health with Refocus</a> <span>May 2017</span></p>

                <p><a href="https://www.meetup.com/CSS-Brigade-Vancouver/events/181732852/">Z-index: Layer the DOM to your will</a> at CSS Brigade <span>May 2014</span></p>
            </div>

            <h2 id="why-do-i-write-this-blog">Why do I write this blog?</h2>
            <p>To express my thoughts clearly.</p>

            <p>There's value in publishing answers to frequently asked questions. It is pragmatic to point people to articles instead of regurgitating on the spot.</p>

            <p>Happy reading!</p>
        </Layout>
    )
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`